import React from "react";
import { useDispatch } from "react-redux";
import { startGoogleLogin } from "../../actions/auth";
import GoogleIcon from "./Google_Icon.png";
import { ContainerAppComponent } from "../ContainerAppComponent";
import "./style.css";

export const Login = () => {
  const dispatch = useDispatch();

  const googleLogin = () => {
    dispatch(startGoogleLogin());
  };

  return (
    <ContainerAppComponent>
      <div className="card-login p-3">
        <p className="title-login mb-2">Iniciar sesión</p>
        <button onClick={googleLogin} className="btn-login-google">
          <img
            src={GoogleIcon}
            alt="google-icon"
            className="google-icon-login"
          />
          Ingresar con Google
        </button>
      </div>
    </ContainerAppComponent>
  );
};
