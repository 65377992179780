import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import "./style.css";

const ModalCancelEditComponent = ({
  show,
  setShow,
  text,
  handleEvent,
  loadingResponse,
}) => {
  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body className="style-modal">
        {loadingResponse ? (
          <div className="pt-4 pb-4">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <p className="text-title-modal">Espere un momento ...</p>
              <Spinner
                animation="border"
                role="status"
                style={{
                  color: " #7340D8",
                }}
                className="mt-2"
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center flex-column pt-4 pb-4">
            <p className="text-title-modal">¿Querés {text} tu turno?</p>
            <div className="contain-btn-modal mt-4">
              <button
                className="me-md-3 mb-2 mb-md-0 btn-outlined btn-mobile"
                onClick={handleClose}
              >
                No
              </button>
              <button
                className="btn-contained btn-mobile"
                onClick={handleEvent}
              >
                {`${text.charAt(0).toUpperCase() + text.slice(1)} turno`}
              </button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalCancelEditComponent;
