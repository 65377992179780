import React from "react";
import { useSelector } from "react-redux";
import "./style.css";

export const Header = () => {
  const { name } = useSelector((state) => state.authReducer);

  return (
    <>
      {name ? (
        <div className="container-user-name">
          <p className="text-user-name">Hola, {name}</p>
        </div>
      ) : null}
    </>
  );
};
