import { types } from "../types/types";

export const turnosReducer = (state = {}, action) => {
  switch (action.type) {
    case types.PutTurnos:
      return {
        ...state,
        data: action.payload.data,
      };

    case types.Loading:
      return {
        ...state,
        loading: action.payload.loading,
        status: action.payload.status,
      };

    default:
      return state;
  }
};
