import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { login } from "../actions/auth";
import { firebase } from "../firebase/firebase-config";
import { Login } from "../components/Login/Login";
import { ResumenTakeComponent } from "../components/Resumen/ResumenTakeComponent";
import { ResumenCheckComponent } from "../components/Resumen/ResumenCheckComponent";
import { ContainAppointment } from "../components/ContainAppointment";

export const AppRouter = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user?.uid) {
        dispatch(
          login(user.uid, user.displayName ? user.displayName : "", user.email)
        );
      }
    });
  }, [dispatch]);

  return (
    <div>
      <Router>
        <Routes>
          <Route
            exact
            path="/resumen-inspeccion/:deal_id"
            element={<ResumenCheckComponent />}
          />
          <Route
            exact
            path="/inspeccion/:deal_id"
            element={<ContainAppointment />}
          />
          <Route exact path="/toma/:deal_id" element={<ContainAppointment />} />
          <Route
            exact
            path="/resumen-toma/:deal_id"
            element={<ResumenTakeComponent />}
          />
          <Route exact path="/login" element={<Login />} />
        </Routes>
      </Router>
    </div>
  );
};
