export const colors = {
  text: {
    primary: "rgba(243, 244, 246, 1)",
    secondary: "rgba(243, 244, 246, 0.87)",
    placeholder: "rgba(243, 244, 246, 0.6)",
    disabled: "rgba(243, 244, 246, 0.38)",
  },

  error: {
    dark: "#CE1212",
  },
  neutral: {
    light: "#FFFFFF",
    default: "#F7F2FF",
    dark: "#180E31",
    lightTwo: "#899ABA",
    darkTwo: "#00205E",
    green: "#0E983D",
    greenLight: "#DDDDDD",
  },
  status: {
    blue: "#238ee8",
    blueHover: "#1162a6",
    red: "#f40000",
    redHover: "#aa0000",
    yellow: " #ffc90a",
    yellowHover: "#cc9f00",
    green: "#40c734",
    greenHover: "#229b18",
  },
  purple: {
    darkPurple: "#482481",
    ultraLightPurple: "#E8E1EF",
    lightPurple: "#D7C9EE",
  },

  primary: {
    primary: "#864bfa",
    secondary: "#31156d",
    secondaryTwo: "#5f35bc",
    secondaryLight: "#d9c4ff",
    secondaryUltraLight: "#f7f2ff",
    secondaryDark: "#1B2130",
    secundaryTransparent: "#d9c4ff4d",
  },
  secondary: {
    light: "#0085FF",
    default: "#156BF4",
    dark: "#0A58D6",
    darker: "#0848AF",
    ultralight: "#E1E7EF",
  },

  gray: {
    50: "#E9ECEF",
    100: "#F3F4F6",
    200: "#E8E8E8",
    300: "#D2D2D2",
    400: "#A1A1AA",
    500: "#71717A",
    600: "#3F3F46",
    700: "#282828",
    800: "#1A1A1A",
    900: "#101010",
  },
  gris: {
    100: "#F8F8F8",
    200: "#E8E8E8",
    300: "#D2D2D2",
    400: "#A1A1AA",
    500: "#71717A",
    600: "#3F3F46",
    700: "#282828",
    800: "#1A1A1A",
    900: "#101010",
    1000: "#818181",
  },

  disabled: {
    100: "#C5C8CC",
  },
};
