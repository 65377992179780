import Swal from "sweetalert2";
import { types } from "../types/types";
import axios from "axios";

export const cancelarTurnoDbTake = async (
  deal_id,
  email,
  navigate,
  dispatch,
  setLoadingResponse
) => {
  const data = {
    year: "0000",
    month: "",
    day: "0",
    time: "",
    dealId: deal_id,
    type: "pretoma",
    agent: email,
    source: "auta",
  };

  let response = axios
    .post("https://infoauto-348420.rj.r.appspot.com/appointment", data)
    .then((dataDb) => {
      setLoadingResponse(false);
      Swal.fire("Cancelado", "Turno cancelado correctamente.", "success");
      dispatch(sendPutTurnos("cancelar"));
      navigate(`/toma/${deal_id}`);
    })
    .catch((e) => {
      console.log(e);
    });
};

export const modificarTurnoDbTake = async (
  deal_id,
  email,
  navigate,
  dispatch,
  setLoadingResponse
) => {
  const data = {
    year: "0000",
    month: "",
    day: "0",
    time: "",
    dealId: deal_id,
    type: "pretoma",
    agent: email,
    source: "auta",
  };

  let response = axios
    .post("https://infoauto-348420.rj.r.appspot.com/appointment", data)
    .then((dataDb) => {
      setLoadingResponse(false);
      Swal.fire("Realizado", "Ya podés editar tu turno", "success");
      dispatch(sendPutTurnos("modificar"));
      navigate(`/toma/${deal_id}`);
    })
    .catch((e) => {
      console.log(e);
    });
};

export const sendPutTurnos = (data) => ({
  type: types.PutTurnos,
  payload: {
    data,
  },
});
