import { combineReducers } from "redux";
import { turnosReducer } from "./turnosReducer";
import { authReducer } from "./authReducer";
import { appointmentReducer } from "./appointmentReducer";

export const rootReducer = combineReducers({
  turnosReducer,
  authReducer,
  appointmentReducer,
});
