import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "animate.css";
import { cancelarTurnoDbTake, modificarTurnoDbTake } from "../../actions/take";
import { ContainerAppComponent } from "../ContainerAppComponent";
import IconCalendar from "../../images/icon-calendar.svg";
import IconHours from "../../images/icon-hours.svg";
import IconLocation from "../../images/icon-location.svg";
import { TitleCardComponent } from "../TitleCardComponent";
import { CardComponent } from "../CardComponent";
import ModalCancelEditComponent from "../Modals/ModalCancelEditComponent";
import FooterComponent from "../Footer/FooterComponent";
import { getDataAppointment } from "../../services/appointment";

export const ResumenTakeComponent = () => {
  const [showModalEdit, setshowModalEdit] = useState(false);
  const [showModalCancel, setshowModalCancel] = useState(false);
  const [loadingResponse, setLoadingResponse] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { deal_id } = useParams();

  useEffect(() => {
    getDataAppointment(deal_id, dispatch, navigate, "pretake");
  }, []);

  const { email } = useSelector((state) => state.authReducer);

  const { appointment } = useSelector((state) => state.appointmentReducer);

  const modalEditar = () => {
    setshowModalEdit(!showModalEdit);
  };

  const modalCancelar = () => {
    setshowModalCancel(!showModalCancel);
  };

  const editarTurno = () => {
    setLoadingResponse(true);
    modificarTurnoDbTake(
      deal_id,
      email,
      navigate,
      dispatch,
      setLoadingResponse
    );
  };

  const cancelarTurnos = () => {
    setLoadingResponse(true);
    cancelarTurnoDbTake(deal_id, email, navigate, dispatch, setLoadingResponse);
  };

  return (
    <ContainerAppComponent>
      <ModalCancelEditComponent
        show={showModalEdit}
        setShow={setshowModalEdit}
        text={"editar"}
        handleEvent={editarTurno}
        loadingResponse={loadingResponse}
      />
      <ModalCancelEditComponent
        show={showModalCancel}
        setShow={setshowModalCancel}
        text={"cancelar"}
        handleEvent={cancelarTurnos}
        loadingResponse={loadingResponse}
      />
      <>
        <CardComponent>
          <TitleCardComponent text={"Mi turno"} />
          <div className="p-1">
            <div className="d-flex align-items-center justify-content-between">
              <p className="text-type-appointment mt-3">Toma</p>
              <div className="d-flex mt-3">
                <div className="d-flex align-items-center me-3">
                  <img src={IconCalendar} alt="icon-calendar" />
                  <p className="text-date-resumen">
                    {appointment?.appointment?.fecha}
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <img src={IconHours} alt="icon-hours" />
                  <p className="text-date-resumen">
                    {appointment?.appointment?.horario}
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center mt-1">
              <img src={IconLocation} alt="icon-location" />
              <p className="text-date-resumen">
                {appointment?.appointment?.location}
              </p>
            </div>
            <div className="mt-4">
              <p className="text-type-car">Vehículo</p>
              <div className="d-flex mt-1">
                <p className="text-data-car">{appointment?.car?.brand}</p>
                <p className="ms-2 text-data-car">
                  {appointment?.car?.model}-{appointment?.car?.version}
                </p>
              </div>
              <p className="text-data-car mt-1">{appointment?.car?.year}</p>
              <p className="text-data-car mt-1">{appointment?.patente}</p>
            </div>
            <div className="d-flex align-items-center justify-content-center mt-5">
              <button className="me-3 btn-contained" onClick={modalEditar}>
                Editar turno
              </button>
              <button className="btn-outlined" onClick={modalCancelar}>
                Cancelar turno
              </button>
            </div>
          </div>
        </CardComponent>
        <div className="d-flex align-items-center justify-content-center flex-column mt-3">
          <FooterComponent />
        </div>
      </>
    </ContainerAppComponent>
  );
};
