import React from "react";
import "../styles/style.css";
import LogoAuta from "../images/logo-auta.png";
import { Header } from "./Header/Header";

export const ContainerAppComponent = ({ children }) => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-between flex-column container-app">
        <Header />
        <div style={{ height: "20vh" }}>
          <img src={LogoAuta} alt="logo-auta" className="logo-auta mt-5" />
        </div>
        <div style={{ height: "80vh" }}>
          <div>{children}</div>
        </div>
      </div>
    </>
  );
};
