import axios from "axios";
import { getAppointment } from "../actions/appointment";
import Swal from "sweetalert2";

const BASE_URL = "https://infoauto-348420.rj.r.appspot.com";

export const redirectUser = async (
  type,
  deal_id,
  setvalidandoAppointment,
  navigate,
  dispatch
) => {
  axios
    .get(`${BASE_URL}/get/appointment/${type}/${deal_id}`)
    .then((response) => {
      // Obtenemos los datos
      getDataAppointment(deal_id, dispatch, navigate, type);
      if (response.data.current_appointment) {
        setvalidandoAppointment(true);
        if (type === "pretake") {
          navigate(`/resumen-toma/${deal_id}`);
        } else {
          navigate(`/resumen-inspeccion/${deal_id}`);
        }
      } else {
        setvalidandoAppointment(false);
      }
    })
    .catch((e) => {
      // Capturamos los errores
      const requestError = JSON.parse(e.request.response);
      if (requestError.message === "deal doesn't exist") {
        Swal.fire(
          "Error",
          "El deal id no existe en la base de datos o es incorrecto.",
          "error"
        );
        return navigate(`/`);
      }
    });
};

export const getDataAppointment = (deal_id, dispatch, navigate, type) => {
  axios
    .get(`${BASE_URL}/get/appointment/${type}/${deal_id}`)
    .then((resp) => {
      dispatch(getAppointment(resp.data));
      // setSchedules(resp.data);
    })
    .catch((e) => {
      console.log(e);
    });
};
