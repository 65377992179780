import React from "react";
import { Spinner } from "react-bootstrap";

export const ModalLoading = ({ modal, setmodal, loading, status }) => {
  return (
    <>
      {modal ? (
        <div className="modal-overlay" style={{ zIndex: "3" }}>
          <div className="modal-success">
            <div className="d-flex align-items-center justify-content-center flex-column">
              {loading ? (
                <>
                  <p className="text-title-modal">Espere un momento ...</p>
                  <Spinner
                    animation="border"
                    role="status"
                    style={{
                      color: " #7340D8",
                    }}
                    className="mt-2"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </>
              ) : null}
              {!loading && status !== 200 ? (
                <>
                  <p className="text-title-modal">
                    Ocurrió un error. <br />
                    Por favor intente nuevamente.
                  </p>
                </>
              ) : !loading && status === 200 ? (
                <p className="text-title-modal">
                  Turno agendado correctamente.
                </p>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
