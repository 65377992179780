import React from "react";
import "./style.css";

const FooterComponent = () => {
  return (
    <>
      <p className="text-footer-resumen">
        Los turnos tienen una tolerancia de hasta 15 minutos*
      </p>
      <p className="text-footer-resumen">
        Concurrir con toda la documentación del vehícula requerida*
      </p>
    </>
  );
};

export default FooterComponent;
