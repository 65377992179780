import React from "react";
import "../Select/style.css";
import "./style.css";
import { colors } from "../../styles/colors";

const PlateComponent = ({
  valuePatente,
  handleInputChange,
  diaSeleccionado,
  horarioSeleccionado,
  locationSelect,
}) => {
  return (
    <>
      <p
        className="label-input"
        style={{
          color:
            diaSeleccionado && horarioSeleccionado && locationSelect !== ""
              ? colors.purple.purple
              : colors.purple.lightPurple,
        }}
      >
        Patente
      </p>
      <input
        type="text"
        maxLength={7}
        value={valuePatente}
        placeholder="Ingresar la patente"
        className="input-plate"
        style={{ width: "100%" }}
        onChange={handleInputChange}
        disabled={
          diaSeleccionado && horarioSeleccionado !== "" && locationSelect !== ""
            ? false
            : true
        }
      />
    </>
  );
};

export default PlateComponent;
