import React from "react";
import "./style.css";
import { colors } from "../../styles/colors";

const HoursComponent = ({ activeHorario, horarios, seleccionarHorarios }) => {
  return (
    <>
      <div className={activeHorario ? "container-hours" : "displayNone"}>
        <div className="subcontainer-hours">
          {activeHorario && horarios !== undefined
            ? horarios.map((info, i) => (
                <div key={i}>
                  <div
                    className="container-buttons-option-hours"
                    key={info.horario}
                    style={{
                      border: `1px solid ${
                        info.available === true
                          ? colors.primary.primary
                          : colors.purple.lightPurple
                      } `,
                      color: `${
                        info.available === true
                          ? "#FFF"
                          : colors.purple.lightPurple
                      } `,
                      cursor: `${
                        info.available === true ? "pointer" : "auto"
                      } `,
                      backgroundColor:
                        info.available === true
                          ? colors.primary.primary
                          : "#FFF",
                    }}
                  >
                    <div
                      onClick={() =>
                        info.available === true
                          ? seleccionarHorarios(info.horario)
                          : null
                      }
                    >
                      {info.horario}
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </>
  );
};

export default HoursComponent;
