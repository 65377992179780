import React from "react";
import "../styles/style.css";
import ArrowBack from "../images/arrow-back.svg";

export const TitleCardComponent = ({
  text,
  arrowBack,
  setShowBack,
  handleClick,
}) => {
  return (
    <>
      <div className="title-card d-flex align-items-center justify-content-center p-2">
        {arrowBack ? (
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ width: "100%" }}
          >
            <img
              src={ArrowBack}
              alt="arrow-back"
              className="arrow-back"
              onClick={handleClick}
              style={{ cursor: "pointer" }}
            />
            <div style={{ width: "100%" }}>
              <p className="text-title-card" style={{ marginRight: "22px" }}>
                {text}
              </p>
            </div>
          </div>
        ) : (
          <p className="text-title-card">{text}</p>
        )}
      </div>
    </>
  );
};
